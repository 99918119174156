@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-Bold.eot');
    src: url('GoogleSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('GoogleSans-Bold.woff2') format('woff2'),
        url('GoogleSans-Bold.woff') format('woff'),
        url('GoogleSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-Medium.eot');
    src: url('GoogleSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('GoogleSans-Medium.woff2') format('woff2'),
        url('GoogleSans-Medium.woff') format('woff'),
        url('GoogleSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Google Sans';
    src: url('GoogleSans-Regular.eot');
    src: url('GoogleSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('GoogleSans-Regular.woff2') format('woff2'),
        url('GoogleSans-Regular.woff') format('woff'),
        url('GoogleSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

