body{
  font-family: 'Google Sans';
  font-weight: normal;
}
img {
    max-width: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}



button{
  box-shadow: inherit !important;
}
.headerBg {
    background: #233036 ;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 9999;
}
.headerBg nav.navbar {
    padding: 26px 50px;
    border-bottom: 1px solid #29363b;
}
.headerBg nav.navbar a.navbar-brand {
    font-size: 24px;
    font-weight: 500;
}
.headerBg .navbar-nav .nav-link {
    color: #fff;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 24px;
    padding: 0;
}
.vds-form-wrapper .youtube-downloadfrm form.vds-form button, .youtube-dataText .btn.btn-primary {
    background: #c80037;
    border-color: #c80037;
}
.vds-form-wrapper .youtube-downloadfrm form.vds-form button{
    padding-left: 26px;
    padding-right: 26px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .8px;
    border-radius: 40px;
}

.vds-form-wrapper .youtube-downloadfrm form.vds-form .input-group-append {
    position: absolute;
    right: -1px;
    height: 100%;
    z-index: 5;
}

.data-details {
    width: 100%;
}

.data-details a.btn.btn-outline-primary, .youtube-dataText button.btn.btn-outline-primary {
    color: #c80037;
    border-color: #c80037;
}

.data-details a.btn.btn-outline-primary:hover, .youtube-dataText button.btn.btn-outline-primary:hover {
    background: #c80037;
    color: #fff;
}
.spinnerSec .spinnerbg{
    background: #c80037;
}
.download-sec {
    padding: 80px 0 100px;
    background-color: #233036;
    color: #fff;
    /*background-image: url(bg.png);*/
    background-attachment: fixed;
    height: calc(100vh - 200px);;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

}
.download-sec-main {
    width: 100%;
}

.vds-form-wrapper {
    width: 100%;
    max-width: 880px;
}

.vds-form-wrapper form.vds-form {
    border-radius: 60px;
    overflow: hidden;
    position: relative;
}

.vds-form-wrapper form.vds-form input.form-control {
    min-height: 60px;
    padding-left: 50px;
}
.vds-form-wrapper form.vds-form input.form-control::placeholder{
  color: #999999;
}
.vds-form-wrapper form.vds-form svg.email-icon {
    position: absolute;
    z-index: 3333;
    top: 50%;
    transform: translateY(-50%) rotate(44deg);
    left: 21px;
    fill: #c7c7c7;
}


.youtube-downloadfrm input[type="text"] {
    min-height: 50px;
}

.youtube-downloadfrm  button.btn.btn-primary {
    padding-left: 30px;
    padding-right: 30px;
}

.vds-thumbnail-block svg {
    position: absolute;
    left: 50%;
    top: 50%;
    font-size: 65px;
    transform: translate(-50%);
    fill: #fff;
    filter: drop-shadow(0px 0px 6px
    #c80037);
}

.vds-thumbnail-block {
    position: relative;
}
.vds-banner-gif {
    margin-bottom: 40px;
}
.download-sec-text p {
    margin: 5px 0;
    letter-spacing: 1.3px;
}

.download-sec-text {
    margin-bottom: 30px;
}

.trending-video {
    padding: 0px 50px;
    text-align: left;
    margin: 15px 0;
}

.trending-video h3 {
    font-size: 22px;
    color: #233036;
    font-weight: bold;
    margin-bottom: 22px;
}

.trending-video .item-slide-img img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.trending-video .item-slide {
    padding: 0 10px;
}

.trending-video .slick-list {
    margin-left: -10px;
    margin-right: -10px;
    padding: 15px 0px;
    overflow: visible;
}
.trending-video-focus {
    overflow: hidden;
}
.trending-video-focus {
    padding: 0 18px;
    margin-left: -18px;
    margin-right: -18px;
}
.trending-video .item-slide .item-slide-img {
    border-radius: 8px;
    position: relative;
    height: 195px;
}

.trending-video .item-slide .item-slide-img img {
    border-radius: 8px;
    transition: .4s all ease;
}
.trending-video .item-slide .item-slide-img:hover img, .trending-video .item-slide.selected .item-slide-img img {
    transform: scale(1.1);
}
.item-slide-text {
    position: absolute;
    bottom: 0;
    padding: 0 24px;
    visibility: hidden;
    opacity: 0;
    transition: .5s all ease;
}

.trending-video .item-slide .item-slide-img:hover .item-slide-text, .trending-video .item-slide.selected .item-slide-img .item-slide-text {
  opacity: 1;
  visibility: visible;
  }
.vds-go-home-bar button{
    margin-top: 3rem!important;
    margin-bottom: 0!important;
}
.item-slide-text button, .vds-go-home-bar button {
    background: #ffffff;
    color: #c80037;
    font-size: 14px;
    border: 2px solid #fff;
    text-transform: uppercase;
    padding: 8px 25px 8px 19px;
    letter-spacing: 1px;
    font-weight: bold;
    margin: 0 4px 20px 4px;
}
.vds-go-home-bar button{
    border: 2px solid #c80037;
}
.vds-results .alert.alert-info {
    background: #2b7900ba;
    color: #fff;
    font-size: 18px;
}
.vds-loader-message .alert.alert-info{
    font-size: 18px;
    color: #000;
    background: rgba(255,255,255,.8);
}
.item-slide-text button svg {
    margin: -1px 6px 0px 0;
    font-size: 17px;
}

.item-slide-text button:hover, .vds-go-home-bar button:hover {
  background: transparent;
    border: 2px solid #fff;
}
.vds-go-home-bar button:hover{
    border: 2px solid #c80037;
    background: #c80037;
    color: #fff;
}
.item-slide-text p {
    font-size: 20px;
    color: #fff;
    font-weight: normal;
    margin: 0;
}

.item-arrow {
    text-align: center;
}

.item-arrow svg {
    color: #fff;
    font-size: 37px;
    margin-bottom: 10px;
}
.trending-video .slick-arrow::before {
  display: none !important;
}

.trending-video .slick-arrow{
    background-image: url(left-arrow.png) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    position: absolute;
    right: 0;
    left: auto;
    top: -33px;
}
.trending-video .slick-arrow.slick-prev {
    right: 34px;
}
.trending-video .slick-arrow.slick-next {
    transform: rotate(180deg);
    top: -41px;
}
.video-header {
    background: #233036;
    padding: 38px 50px;
    border-top: 1px solid #29363b;
}

.video-header ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
}

.video-header ul li {
    display: inline-block;
    margin: 0 30px;
}

.video-header ul li a:not([href]):not([tabindex]) {
    font-size: 14px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    cursor: pointer;
}

.videoplay-sec {
    position: relative;
    overflow: hidden;
    /* min-height: 555px; */
    display: flex;
    align-items: flex-end;
    padding: 12.4% 0 5% 0;
    margin-bottom: 30px;
    border-radius: 8px;
}
.videoplay-sec.vds-videoplay-active {
    padding: 36.2% 0 0 0;
}
.videoplay-sec .item-slide-text {
    opacity: 1;
    visibility: visible;
    position: absolute;
    bottom: 14%;
    z-index: 3;
    padding: 0 10px;
    width: 100%;
    max-width: 735px;
    margin-left: 100px;
}
.videoplay-sec .item-slide-text {
    opacity: 1;
    visibility: visible;
    position: static;
    bottom: 14%;
    z-index: 3;
    padding: 0 10px;
    width: 100%;
    max-width: 735px;
    margin-left: 100px;
}
.videoplay-sec .item-slide-text button {
    padding: 16px 35px 16px 29px;
}

.videoplay-sec .item-slide-text h3 {
    font-size: 32px;
    color: #fff;
    margin: 30px 0 22px 0px;
    font-weight: 500;
}

.videoplay-sec .item-slide-text p {
    font-size: 16px;
}
.videoplay-sec img {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    top: 0;
    bottom: 0;
    border-radius: 8px;
    object-fit: cover;
}
.vds-video-player-wrap > div {
    height: 100% !important;
}

.vds-video-player-wrap > div video {
    height: 100%;
    width: 100%;
}

.vds-video-player-wrap {
    height: 100%;
    width: auto;
    max-width: 100%;
    flex: 0 0 100%;
    margin-left: 0;
    margin-right: 0;
    background: #efefef;
}

.sec-close {
    position: absolute;
    z-index: 5;
    right: 30px;
    top: 33px;
    background: rgba(255, 255, 255, .1);
    border-radius: 100%;
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .4s all ease;
    box-shadow: 1px 2px 6px 1px rgba(0, 0, 0, 0.30);
}

.sec-close:hover {
    background: rgba(255, 255, 255, .2);
  }
.sec-close svg {
    color: #fff;
    font-size: 51px;
}
.trending-video-sec {
    margin: 40px 0;
    padding-bottom: 10px;
    overflow: hidden;
}
.downloadVideo-sec {
    background: #f3f3f3;
    padding: 60px 0 35px;
}

.downloadVideo-sec .container {
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}

.downloadVideo-sec  h2 {
    font-size: 36px;
    color: #233036;
    font-weight: bold;
    margin-bottom: 25px;
}

h3.catch-head {
    font-size: 20px;
    color: #c80037;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 0 50px;
}

.downloadVideo-sec p {
    color: #666666;
    font-size: 16px;
    margin: 26px 0;
}
.support-logo h3.catch-head {
    margin: 0  0 30px;
}

.support-logo {
    padding: 70px 50px;
}

.support-logo ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.support-logo ul li {
    display: inline-block;
}

.support-logo ul li span {
    background: #fff9fb;
    width: 170px;
    height: 80px;
    border: 1px solid #ffcad8;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    margin: 5px;
}

.copyrigh {
    background: #233036;
    padding: 18px 15px;
}

.copyrigh p {
    font-size: 16px;
    letter-spacing: 1px;
    color: #fff;
    margin: 0;
}




.bannerBgEffet {
    position: absolute;
    left: 0;
    right: 0;
}







/* ---- reset ---- */ body{ margin:0; font:normal 75% Arial, Helvetica, sans-serif; } canvas{ display: block; vertical-align: bottom; } /* ---- particles.js container ---- */ #particles-js{ position:absolute; width: 100%; height: 100%; background-color: #b61924; background-image: url(""); background-repeat: no-repeat; background-size: cover; background-position: 50% 50%; } /* ---- stats.js ---- */ .count-particles{ background: #000022; position: absolute; top: 48px; left: 0; width: 80px; color: #13E8E9; font-size: .8em; text-align: left; text-indent: 4px; line-height: 14px; padding-bottom: 2px; font-family: Helvetica, Arial, sans-serif; font-weight: bold; } .js-count-particles{ font-size: 1.1em; } #stats, .count-particles{ -webkit-user-select: none; margin-top: 5px; margin-left: 5px; } #stats{ border-radius: 3px 3px 0 0; overflow: hidden; } .count-particles{ border-radius: 0 0 3px 3px; }






.videoplay-sec::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#c80138+20,c60035+100&1+0,0+100 */
background: -moz-linear-gradient(left,  rgba(200,1,56,1) 0%, rgba(200,1,56,0.8) 20%, rgba(198,0,53,0) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(left,  rgba(200,1,56,1) 0%,rgba(200,1,56,0.8) 20%,rgba(198,0,53,0) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right,  rgba(200,1,56,1) 0%,rgba(200,1,56,0.8) 20%,rgba(198,0,53,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c80138', endColorstr='#00c60035',GradientType=1 ); /* IE6-9 */

    width: 100%;
    height: 100%;
    z-index: 2;
}

.spinnerSec .spinnerbg {
    background: #fff;
}
.videoplay-sec.vds-videoplay-active .item-slide-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 100%;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}














@media(max-width: 1599px){
    .item-slide-text p {
    font-size: 16px;
}
.item-arrow svg {
    margin-bottom: 0;
    height: 34px;
}

.item-slide-text {
    padding: 0 0px 0 11px;
}

.item-slide-text button {
    margin-bottom: 10px;
}











    }


@media(max-width: 1023px){


.headerBg nav.navbar {
    padding: 26px 15px;
}
.video-header {
    padding: 30px 15px;
}
.video-header ul li a:not([href]):not([tabindex]) {
    font-size: 13px;
}
.video-header ul li {
    margin: 5px 10px;
}
.support-logo ul li span {
    width: 120px;
    height: 60px;
}
.support-logo li img {
    max-width: 50%;
}

.download-sec {
    height: auto;
    padding: 30px 0 50px;
}











}


@media(max-width: 767px){


.vds-form-wrapper .youtube-downloadfrm form.vds-form .input-group-append {
    position: relative;
    right: 0;
    margin: 0 auto;
    right: 0;
    max-width: 280px;
    display: block;
}

.vds-form-wrapper form.vds-form input.form-control {
    width: 100%;
    border-radius: 40px;
    min-height: 50px;
    margin-bottom: 15px;
}

.vds-form-wrapper form.vds-form {
    border-radius: 0;
}

.vds-form-wrapper form.vds-form svg.email-icon {
    top: 26px;
}

.vds-form-wrapper .youtube-downloadfrm form.vds-form button {
    min-height: 45px;
}
h3.catch-head {
    font-size: 16px;
}
.support-logo {
    padding: 50px 15px;
}
.copyrigh p {
    font-size: 14px;
}
.downloadVideo-sec h2 {
    font-size: 28px;
}
.trending-video {
    padding: 0px 15px;
}
.videoplay-sec .item-slide-text {
    margin-left: 20px;
}
.videoplay-sec .item-slide-text h3 {
    font-size: 24px;
}
.sec-close svg {
    font-size: 40px;
}
.sec-close {
    top: 10px;
    right: 10px;
}
.videoplay-sec.vds-videoplay-active {
    padding: 56.2% 0 0 0;
}
.vds-banner-gif img {
    max-width: 70%;
}

.vds-banner-gif {
    margin-bottom: 15px;
}
.headerBg nav.navbar {
    padding: 16px 15px;
}
}

@media(max-width: 575px){
  .data-details table thead {
    display: none;
}

.data-details table tbody tr td::before {content: attr(data-label);float: left;font-weight: bold;}

.data-details table tbody tr td {
    width: 100%;
    text-align: right;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.data-details table tbody tr td a.btn.btn-outline-primary {
    font-size: 12px;
}

h2 {
    font-size: 1.8rem;
}
.youtube-downloadfrm input[type="text"] {
    width: 100%;
    border-radius: 4px !important;
    margin-bottom: 12px;
}

.youtube-downloadfrm .input-group {
    justify-content: center;
}

.youtube-downloadfrm button.btn.btn-primary, .youtube-dataText .btn.btn-primary {
    border-radius: 4px;
}
.headerBg nav.navbar a.navbar-brand {
    font-size: 16px;
    }
    .video-header ul li a:not([href]):not([tabindex]) {
    font-size: 11px;
}
}
